<div class="article-card">
    <div class="article-info">
      <h2 class="article-title" title="{{article.article_title}}" (click)="navigateToArticle(article.article_link,article.category_heading.id)" [innerHTML]="article.article_title"></h2>
      <p *ngIf="article.authors && article.authors.length > 0">
        <!-- Use helper methods to check the type of authors array -->
        <span *ngIf="isStringArray(article.authors)">
            {{ article.authors.join(', ') }}
        </span>
        <span *ngIf="isObjectArray(article.authors)">
            <span *ngFor="let author of article.authors; let isLast = last">
                {{ author.name }}<span *ngIf="!isLast">, </span>
            </span>
        </span>
    </p>
      <!-- <p class="article-journal">Ann Surg 2024; 279: 923-931.</p>
      <p class="article-excerpt">
        In this study that included 236 patients, the number of lymph nodes retrieved was significantly higher in the indocyanine green group: 48 versus 38, P&lt;0.001.
      </p>
      <p class="article-comment"><strong>Comment:</strong> Clinical outcomes awaited.</p> -->
      <p *ngIf="article.published_online" class="article-date">{{ article.published_online }}</p>
      <div class="article-tags">
        <a *ngIf="returnCategory(article.category_heading.id)" class="tag-content">
            <span [innerHTML]="article.category_heading.title">
            </span>
        </a>
        <a *ngFor="let tag of article.surgical_speciality" class="tag-content">
            <span [innerHTML]="tag"></span>
        </a>
        <a *ngFor="let type of article.content_type" class="tag-content">
            <span [innerHTML]="type"></span>
        </a>
      </div>
      <div class="row">
        <div class="col-10 rct-article-abstract">
            <a class="download" (click)="navigateToArticle(article.article_link,article.category_heading.id)">
                <span>View individual abstract</span>
            </a>    
            <a class="download" href="{{article.read_paper}}" target="_blank">
                <span>Read paper</span>
            </a>
        </div>
        <div class="col-2">
            <div class="share-button d-flex justify-content-end">
                <div class="dropdown">
                    <span class="dropdown-toggle" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <fa-icon [icon]="['fas', 'share-nodes']" class="share-icon"></fa-icon>
                    </span>
                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <span class="dropdown-item p-2" href="#">
                                <share-button button="facebook" text="Share" showText
                                [url]="sharedUrl+'/academy/'+article.category_heading+'/'+article.article_link"></share-button>
                            </span>
                        </li>

                        <li>
                            <span class="dropdown-item p-2" href="#">
                                <share-button button="x" text="Twitter" showText
                                [url]="sharedUrl+'/academy/'+article.category_heading+'/'+article.article_link"></share-button>
                            </span>
                        </li>

                        <li>
                            <span class="dropdown-item p-2" tabindex="0" title="Linkedin">
                                <share-button button="linkedin" text="Linkedin" showText
                                    [url]="sharedUrl+'/academy/'+article.category_heading+'/'+article.article_link"></share-button>
                            </span>
                        </li>
                        <li>
                            <span class="dropdown-item p-2" tabindex="0" title="Email">
                                <share-button button="email" text="Email" showText
                                    [url]="sharedUrl+'/academy/'+article.category_heading+'/'+article.article_link"></share-button>
                            </span>
                        </li>
                        <li><span class="dropdown-item p-2">
                                <share-button button="copy" text="Copy Link" showText
                                    [url]="sharedUrl+'/academy/'+article.category_heading+'/'+article.article_link"></share-button>
                            </span>
                        </li>

                    </ul>

                </div>
            <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
  