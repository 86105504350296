import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { env } from '../../config/config';

@Component({
	selector: 'app-rct-article-card',
	templateUrl: './rct-article-card.component.html',
	styleUrl: './rct-article-card.component.scss'
})
export class RctArticleCardComponent implements OnInit {
	@Input() article: any;
	sharedUrl: string;
	constructor(
		private router : Router
	) { }
	ngOnInit() {
		this.sharedUrl = env.siteUrl;
	 }
	isStringArray(authors: any[]): boolean {
		return typeof authors[0] === 'string';
	}

	isObjectArray(authors: any[]): boolean {
		return typeof authors[0] === 'object';
	}

	returnCategory(category: string) {
		if (category == 'social-media' || category == 'randomized-clinical-trials' || category == 'surgical-science' || category == 'surgery-for-all') {
			return true;
		}
		else {
			return false;
		}
	}

	navigateToArticle(article_link: any, category_heading: any) {
		if (category_heading) {
			this.router.navigate(['/bjs-academy', category_heading, article_link]).then(navigated => {

				if (!navigated) {
					// Handle failed navigation
				}
			});
		}
	}
}
