<section #contentLoaded id="article-view-page">
    <div class="article-view-page">
        <div class="container-fluid">
            <!-- <div class="row ">
            <div class="col-9" > -->
            <div class="row  pe-4 ps-2  pt-3 view-section">
                <div class="col mb-5 me-2 content-area-sm">
                    <div class="row ps-xs-0 ps-sm-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5  ms-0 d-flex flex-xs-column-reverse flex-sm-column-reverse
                             flex-md-column-reverse flex-lg-column-reverse  flex-xl-column-reverse flex-xxl-row">
                        <div class="col-lg-12 col-xl-6 col-xxl-5 ps-0">
                            <div *ngIf="!categorydocheadloading ;else categorydocheadNotLoaded">
                                <h1 *ngIf="articleView.category_dochead" class="about-article"
                                    [innerHtml]="articleView.category_dochead"></h1>
                            </div>

                        </div>
                        <ng-template #categorydocheadNotLoaded>
                            <div>
                                <h1 class="about-article skeleton"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h1>
                            </div>
                        </ng-template>
                        <div class="col-lg-12 col-xxl-7 d-flex justify-content-end wrapper-container"
                            *ngIf="!pageLoading;else pageNotLoaded">
                            <div class="article-view-wrapper d-flex  justify-content-center me-3 me-xs-0">
                                <span class="pe-4 pe-xs-0 d-flex jusitfy-content-center">
                                    <fa-icon [icon]="['fas','eye']" aria-hidden="true"
                                        class="mt-2 m-3 icon-class"></fa-icon>

                                </span>
                                <span class="me-2 article-view-normal "
                                    [ngStyle]="{'background-color': activeViewFont == 'normal' ? '#fff' : ''}"
                                    (click)="openDyslexic('normal')">
                                    Normal View </span>

                                <span class="article-view-dyslexic "
                                    [ngStyle]="{'background-color': activeViewFont == 'dyslexic' ? '#fff' : ''}"
                                    (click)="openDyslexic('dyslexic')">
                                    Dyslexic View
                                </span>
                            </div>
                            <div class="share-button d-flex justify-content-center">
                                <div class="dropdown">
                                    <span class="dropdown-toggle" type="button" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        <fa-icon [icon]="['fas', 'share-nodes']" class="share-icon"></fa-icon>
                                    </span>
                                    <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <span class="dropdown-item p-2" href="#">
                                                <share-button button="facebook" text="Share" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>

                                        <li>
                                            <span class="dropdown-item p-2" href="#">
                                                <share-button button="x" text="Twitter" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>

                                        <li>
                                            <span class="dropdown-item p-2" tabindex="0" title="Linkedin">
                                                <share-button button="linkedin" text="Linkedin" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>
                                        <li>
                                            <span class="dropdown-item p-2" tabindex="0" title="Email">
                                                <share-button button="email" text="Email" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>
                                        <li><span class="dropdown-item p-2">
                                                <share-button button="copy" text="Copy Link" showText
                                                    [url]="sharedUrl"></share-button>
                                            </span>
                                        </li>

                                    </ul>

                                </div>
                            </div>
                        </div>
                        <ng-template #pageNotLoaded>
                            <div class="col-lg-12 col-xxl-7 d-flex justify-content-end wrapper-container">
                                <div class="article-view-wrapper d-flex  justify-content-center me-3 me-xs-0">
                                    <div class="skeleton">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="share-button d-flex justify-content-center">
                                    <div class="skeleton"></div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="row ps-xs-0 ps-sm-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5">
                        <div class="col" *ngIf="!articleTitleLoading;else articleTitleNotLoaded">
                            <h1 class="article-title" [innerHtml]="articleView.article_title"></h1>
                        </div>
                        <ng-template #articleTitleNotLoaded>
                            <div class="col">
                                <h1 class="article-title skeleton skeleton-text skeleton-heading mt-4"></h1>
                            </div>
                        </ng-template>
                    </div>
                    <div class=" ps-xs-0 ps-sm-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5 d-flex reverse-sm-col mt-4">
                        <div class=" cover-area pe-5">
                            <div class="cover-image" *ngIf="!coverImgLoading && !categorydocheadloading;else coverImgNotLoaded"
                                >
                                <img src={{setCoverImage(articleView?.article_cover_image)}} class="cover-image-thumbnail img-fluid" width="335"
                                    height="180" alt="cover image" (click)="loadModal($event)">
                            </div>
                            <ng-template #coverImgNotLoaded>
                                <div class="cover-image">
                                    <div class="cover-image-thumbnail img-fluid skeleton skeleton-img"></div>
                                </div>
                            </ng-template>
                            <div *ngIf="download_url!='' " class="download">
                                <a href={{download_url}} target="_blank">
                                    <fa-icon [icon]="['fas','download']" class=" me-2"></fa-icon>
                                    <span>{{downloadText}}</span></a>
                            </div>
                        </div>
                        <div class="  cover-area-second  w-100 ps-2">

                            <!-- calling author component -->
                            <ng-container *ngIf="!authorViewLoading;else ArticleViewNotLoaded">
                                <app-authors *ngIf="articleView.authors" [authors]="articleView.authors"
                                    [article_path]="articleView.art_file_path"></app-authors>
                            </ng-container>
                            <ng-template #ArticleViewNotLoaded>
                                <app-authors [pageloading]="authorViewLoading"></app-authors>
                            </ng-template>

                            <div class="d-flex flex-column" *ngIf="!articleDoiLoading;else articleDoiNotLoaded">
                                <!-- <div class="col"> -->
                                <div class="published-date" [innerHtml]="articleView.published_date">
                                </div>
                                <div class="article-doi mt-1" *ngIf="articleView.doi">
                                    <span>
                                        <fa-icon [icon]="['fas','earth-americas']"
                                            class="earth-americas me-2"></fa-icon>
                                        <a href={{articleView.doi}} [innerHtml]="articleView.doi" target="_blank"></a>
                                    </span>
                                </div>
                                <div class="keyword" *ngIf="surgical_content">
                                    <!-- <span *ngFor="let words of surgical_content" class="one-keyword">{{words}}</span> -->
                                    <!-- <span class="one-keyword ms-3">VASCULAR</span> -->
                                    <span *ngFor="let word of surgical_content" class="one-keyword" (click)="onTagClick(word)">
                                        {{ word.value }}
                                    </span>
                                </div>
                                <!-- </div> -->
                            </div>
                            <ng-template #articleDoiNotLoaded>
                                <div class="d-flex flex-column">
                                    <div class="published-date  ">
                                        <p class="skeleton  "> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                    </div>
                                    <div class="article-doi mt-1">
                                        <p class="'skeleton">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                    </div>

                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-9" *ngIf="!articleContetLoading;else articleContentNotLoaded"
                            style="border-right: 1px solid #d6dce2;">
                            <!---------------article content section----------->
                            <div id="article-content" class="article-content" [innerHtml]="html_new_content_dupe" (click)="refClick($event)" 
                                (keyup.enter)="refClick($event)" (keyup.space)="refClick($event)">
                            </div>

                        </div>

                        <ng-template #articleContentNotLoaded>
                            <div class="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-8">
                                <!---------------article content section----------->
                                <div class="article-content">
                                    <div *ngFor="let each of contentLoadingArray ; let ind=index">
                                        <p class="skeleton skeleton-text"
                                            [ngClass]="ind==(contentLoadingArray.length-1)?'skeleton-text-80':''"></p>
                                    </div>
                                    <div *ngFor="let each of contentLoadingArray ; let ind=index">
                                        <p class="skeleton skeleton-text"
                                            [ngClass]="ind==(contentLoadingArray.length-1)?'skeleton-text-80':''"></p>
                                    </div>

                                </div>

                            </div>
                        </ng-template>
                        <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4" *ngIf="!articleContetLoading;else articleContentTabNotLoaded" (window:scroll)="onScroll($event)" >
                            <div  id="article-info-section"  class="article-info-section"  [ngClass]="{ 'fixed-position': isFixed }"[ngStyle]="isFixed ? { 'width.px': containerWidth } : {}">
                                <ul #listContent1 class="nav nav-tabs pb-2" id="custom-tabs-three-tab" role="tablist">
                                    <li class="nav-item" role="tab" aria-selected="false" id="tab-1"
                                        aria-controls="panel-1">
                                        <a class="nav-link" [ngClass]="{'active': activeTab === 'tab1'}"
                                            (click)="setActiveTab('tab1')" (keyup.enter)="setActiveTab('tab1')"
                                            (keyup.space)="setActiveTab('tab1')" aria-label="Article info" tabindex="0"
                                            title="Article info">
                                            <fa-icon class="iconcls citation-icon-tab black-icon icon-class"
                                                aria-hidden="true" [icon]="['fas', 'circle-info']"></fa-icon>
                                                <span class="ps-2">Article info</span>
                                        </a>
                                    </li>

                                    <li class="nav-item" role="tab" aria-selected="false" id="tab-3"
                                        aria-controls="panel-3" *ngIf="imagePaths.length>0">
                                        <a class="nav-link" [ngClass]="{'active': activeTab === 'tab2'}" tabindex="0"
                                            aria-label="Images" (click)="setActiveTab('tab2')"
                                            (keyup.enter)="setActiveTab('tab2')" (keyup.space)="setActiveTab('tab2')"
                                            title="Images">
                                            <fa-icon class="iconcls citation-icon-tab black-icon icon-class"
                                                aria-hidden="true" [icon]="['fas', 'images']"></fa-icon>
                                            <span class="ps-2">Images <span>({{imagePaths.length}})</span></span>
                                        </a>
                                    </li>
                                </ul>
                                <div #myTabContent id="custom-tabs-three-tab-tabContent" class="myTabContent">

                                    <div class="tab-panes pb-4 block-content mt-3" [hidden]="activeTab !== 'tab1'"
                                        id="panel-9" aria-labelledby="tab-9">
                                        <div class="d-flex justify-content-start mb-4"
                                            *ngIf="articleView?.article_cover_image!=='' " (click)="loadModal($event)">
                                            <img class="card-img-top" tabindex="0" src={{setCoverImage(articleView?.article_cover_image)}}
                                            alt="cover image">
                                        </div>
                                        <ng-container *ngIf="articleView.doi">
                                            <!-- Content for Tab 1 -->
                                           <div class="mb-2">
                                                <span class="tab-content-heading">DOI: </span>
                                                <span class="tab-content-value">
                                                    <a class="link" target="_blank"
                                                        title="{{articleView.doi}}" class="doi-underline"
                                                        href="{{articleView.doi}}">
                                                        {{articleView.doi}}
                                                    </a>
                                                </span>
                                           </div>
                                        </ng-container>
                                        
                                        <ng-container *ngIf="articleView.published_date">
                                            <!-- Content for Tab 1 -->
                                           <div class="mb-2">
                                                <span class="tab-content-heading">Published on: </span>
                                                <span class="tab-content-value">
                                                    {{articleView.published_date}}
                                                </span>
                                           </div>
                                        </ng-container>
                                        <ng-container *ngIf="articleView.article_id">
                                            <div class="mb-2">
                                                <span class="tab-content-heading">Article ID:</span>
                                                <span class="tab-content-value">
                                                    {{articleView.article_id}}
                                                </span>
                                            </div>
                                         </ng-container>
                                        <ng-container>
                                            <!-- Content for Tab 1 -->
                                            <!-- <h5 class="block-content-heading"> <fa-icon
                                                    class="iconcls citation-icon-tab black-icon icon-class"
                                                    aria-hidden="true" [icon]="['fas', 'quote-left']"></fa-icon>
                                                Cite this article
                                                <fa-icon class="iconcls citation-icon-tab black-icon icon-class"
                                                    aria-hidden="true" [icon]="['fas', 'quote-left']"></fa-icon>
                                            </h5>
                                            <span class="block-content-value"> something
                                            </span> -->
                                        </ng-container>
                                    </div>
                                    <div class="tab-panes" [hidden]="activeTab !== 'tab2'">


                                         <ng-container *ngIf="imagePaths.length>0" >
                                                <h4  class="navigation-heading-1 mt-4" tabindex="0">
                                                    <fa-icon class="iconcls citation-icon Outline Outlineicon"
                                                        aria-hidden="true" [icon]="['fas', 'images']">
                                                    </fa-icon>
                                                    <span class="nav-heading-content Outline">
                                                        Images Outline
                                                    </span>
                                                </h4>
                                                <div *ngIf="imagePaths.length!==0 " class="page-content pl-4 page-container"
                                                    id="page-content">
                                                    <div class="row">
                                                        <div class="col-5 pb-3 me-2 ms-2" (click)="loadModal($event)"
                                                            *ngFor="let content of imagePaths; let i = index">
                                                            <img width="20" height="20" class="graphic"
                                                                attr-position="float" attr-orientation="portrait" 
                                                                src={{content.imageUrl}}>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="imagePaths.length===0" class=" page-content pl-4 page-container"
                                                    id="page-content">
                                                    <div class="row">
                                                        <div class="col">
                                                            <div
                                                                style="display: flex; flex-direction: column; align-items: center; padding-bottom: 5px;">
                                                                <!-- <img width="20" height="20" class="no-data-found-img"
                                                                    src="assets/images/not_found_img/no_data_1.webp" alt=""> -->
                                                                <h5 class="card-title  text-head-size">
                                                                    No Data found</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #articleContentTabNotLoaded>
                            <div class="col-3" >
                                <div class="article-info-section info-loader skeleton" >
                                   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                </div>
                            </div>

                            
                        </ng-template>
                    </div>
                    <div *ngIf="relatedArticle.length>0 && !relatedArticleLoading;else RelatedArticleNotLoaded"
                        class="row ps-xs-0 ps-sm-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5 related">
                        <h2>Related articles</h2>
                        <hr class="hr hr--color hr--lg">
                        <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 g-4">
                            <app-article-card-list *ngFor="let article of relatedArticle; let i = index"
                                [viewFont]="activeViewFont" [article]="article" [index]="i"></app-article-card-list>
                        </div>
                    </div>
                    <ng-template #RelatedArticleNotLoaded>
                        <div *ngIf="relatedArticleLoading"
                            class="row ps-xs-0 ps-sm-0 ps-md-5 ps-lg-5 ps-xl-5 ps-xxl-5 related">
                            <h2 class="skeleton skeleton-heading">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                            <hr class="hr hr--color hr--lg">
                            <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 g-4">
                                <app-article-card-list *ngFor="let article of contentLoadingArray; let i = index"
                                    [pageloading]="relatedArticleLoading"></app-article-card-list>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <!-- </div> -->

            <!-- </div> -->
        </div>
    </div>
</section>
<app-modal-fig *ngIf="isModalOpen" (close)="closeJoinModal()" [div_data]="div_data"></app-modal-fig>