import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ArticleService } from '../../services/article.service';
import { SharedService } from '../../services/shared.service';

@Component({
    selector: 'app-academy',
    templateUrl: './academy.component.html',
    styleUrl: './academy.component.scss'
})
export class AcademyComponent implements OnInit, AfterViewInit {
    journalId: string;
    youngBjs: any;
    cseBjs: any;
    cebBjs: any;
    snBjs: any;
        pageloading = true;

    constructor(
        private articleService: ArticleService,
        private sharedService: SharedService
    ) {

    }

    ngOnInit() {
        this.journalId = this.sharedService.journalId;
        // to load latest articles in sections
        if (this.journalId) {
            //get youn-bjs articles
            const youngParam = {
                category: 'young-bjs'
            }
            this.articleService.getLatestArticles(this.journalId, youngParam).subscribe(result => {
                this.youngBjs = result.data;
            })

            //get cse articles
            const cseParam = {
                category: 'continuing-surgical-education'
            }
            this.articleService.getLatestArticles(this.journalId, cseParam).subscribe(result => {
                this.cseBjs = result.data;
            })

            //get ceb articles
            const cebParam = {
                category: 'cutting-edge-blog'
            }
            this.articleService.getLatestArticles(this.journalId, cebParam).subscribe(result => {
                this.cebBjs = result.data;
            })

            //get sn articles
            const snParam = {
                category: 'surgical-news'
            }
            this.articleService.getLatestArticles(this.journalId, snParam).subscribe(result => {
                this.snBjs = result.data;
            })
        }
        
            setTimeout(() => {
                this.pageloading = false;
            }, 500);
    

    }
    ngAfterViewInit() {
        this.journalId = this.sharedService.journalId;
    }
}
