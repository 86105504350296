import { Component, OnInit, OnDestroy, ViewChild, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ArticleService } from '../../services/article.service';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { SearchContextService } from '../../services/search-context-service';

@Component({
  selector: 'app-article-listing',
  templateUrl: './article-listing.component.html',
  styleUrls: ['./article-listing.component.scss']
})
export class ArticleListingComponent implements OnInit, OnDestroy {
  
  load_filter = false;
  subscriptions: Subscription[] = [];
  private _length = 21;
  private _start = 0;
  journalid: string = '';
  articles: any[] = [];
  category: string = '';
  filteredArticles: any[] = [];
  currentPage: number = 1;
  // itemsPerPage: number = 10;
  totalPages: number = 1;
  startArticle: number;
  categories: any;
  categoryTitles: any[];
  categoryTitle: any[];
  CategoryarrayForListing: any;
  allArticles: boolean;
  listLoaderarray = new Array(8).fill(0);
  pageloading = true;
  adspageloading=true;
  total_count: any;
  filter_total_count:any;
  bannerpageloading=true;
  private readonly searchContextService = inject(SearchContextService);

  get surgical_speciality(){
    return this.searchContextService.selectedSpecialities();
  }

  get content_type(){
    return this.searchContextService.selectedContentType();
  }

  get searchValue(){
    return this.searchContextService.searchContent();
  }

   // Getter for start
   get start(): number {
    return (this.currentPage - 1) * this._length;
  }

  // Getter for length
  get length(): number {
    return this._length;
  }
  get surgical_speciality_count(): number {
    const specialities = this.searchContextService.selectedSpecialities();
    return Array.isArray(specialities) ? specialities.length : 0;
  }
  get content_type_count(): number {
    const content = this.searchContextService.selectedContentType();
    return Array.isArray(content) ? content.length : 0;
  }


  constructor(
    private articleService: ArticleService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    //this.resetSearchContext();

    this.journalid = this.sharedService.getJournalId();
    this.route.params.subscribe(params => {
      this.load_filter = false;
    
    if(params['subcategory']){
      this.category = params['subcategory'];
      this.allArticles=false;
    }else{
      this.category = params['category'];
    }
    if( this.category ==="all-articles"){
      this.allArticles=true;
      //this.category='all-articles';
      this.getCategories();
    }
    else{
      this.allArticles=false;
    }
  //  this.getCategories();
    this.loadArticlesByCategory(this.category);
    // this.loadArticlesByCategory(this.category);
      this.currentPage = params['page'] ? +params['page'] : 1; // Default to page 1 if not specified

      if (!params['page']) {
        // If the page parameter is missing, redirect to page 1
        this.router.navigate([`/bjs-academy/${this.category}/page/1`]);
      } 

      // if (!params['page']) {
      //   // If the page parameter is missing, redirect to page 1
      //   this.router.navigate([`/academy/${this.category}/page/1`]);
      // } else {
      //   this.loadArticlesByCategory(this.category);
      // }

      setTimeout(() => {
        this.adspageloading=false;
        this.bannerpageloading=false;
      }, 500);

    });
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams['speciality']) {
        const filterValue = queryParams['speciality'].split(',').map((filter: string) => filter.trim());
        this.searchContextService.setSelectedSpecialities(filterValue);
        const specialityCount = this.surgical_speciality_count;
        const contentTypeCount = this.content_type_count;
 
        this.filter_total_count = specialityCount + contentTypeCount;
      }
      
      if (queryParams['contentType']) {
        const contentType = queryParams['contentType'].split(',').map((filter: string) => filter.trim());
        this.searchContextService.setSelectedContentType(contentType);
        const specialityCount = this.surgical_speciality_count;
        const contentTypeCount = this.content_type_count;
  
        this.filter_total_count = specialityCount + contentTypeCount;
      }
    
     if(this.journalid)
     {
      this.filterDataApi(this.journalid);

     }
    });
    
    
 
  }

  loadArticlesByCategory(category: string): void {
    this.startArticle = this.length * (this.currentPage - 1);
    if(this.journalid){
      this.filterDataApi(this.journalid);
    }
  }

  onFilterChange(filters: { surgical_speciality: string[], content_type: string[] }) {
  
    this.currentPage = 1;
    // this.router.navigate([`/academy/${this.category}/page/${this.currentPage}`]);
    this.filterDataApi(this.journalid)
    if (this.surgical_speciality_count=== 0 && this.content_type_count === 0) {
      // Clear filter count
      this.filter_total_count = 0;
    } else {
      this.filterDataApi(this.journalid);
      const specialityCount = this.surgical_speciality_count;
      const contentTypeCount = this.content_type_count;
  
      this.filter_total_count = specialityCount + contentTypeCount;
    
  
    }

  }
 
  private resetSearchContext(): void {
    this.searchContextService.setSelectedSpecialities([]);
    this.searchContextService.setSelectedContentType([]);
    this.searchContextService.setSearchContent(undefined);

}

  onPageChange(page: number): void {
  
    if (page < 1 || page > this.totalPages) {
      return;
    }
    this.currentPage = page;
    // this.resetSearchContext();
     this.router.navigate([`/bjs-academy/${this.category}/page/${this.currentPage}`]);
    
    // this.start = (this.currentPage - 1) * this.length;
    this._start = (this.currentPage - 1) * this.length;



    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages; 
    } else {
      this.currentPage = page;
    }

    const content_type = this.searchContextService.selectedContentType();
    const surgical_speciality = this.searchContextService.selectedSpecialities();
    const searchValue = this.searchContextService.searchContent()

    const params: any = {
      length: this.length,
      start: this.start,
      category: this.category,
      content_type: JSON.stringify(content_type),
      surgical_speciality: JSON.stringify(surgical_speciality),
      searchValue:searchValue
    };
  
    this.filterDataApi(this.journalid)
  }
  
  updateFilteredArticles() {
    const startIndex = (this.currentPage - 1) * this.length;
    const endIndex = startIndex + this.length;
    this.filteredArticles = this.filteredArticles.slice(startIndex, Math.min(endIndex, this.filteredArticles.length));
  }

  loadArticles(searchValue: string = ''): void {

    this.articleService.getArticles(searchValue, this.category).subscribe(
      (data: any) => {
        this.filteredArticles = data.data;


      },
      (error) => {

      }
    );
  }

  handleSearch(search_value: string) {
    this.searchContextService.setSearchContent(search_value)

    const content_type = this.searchContextService.selectedContentType();
    const surgical_speciality = this.searchContextService.selectedSpecialities();
    const searchValue = this.searchContextService.searchContent()

    const params: any = {
      length: this.length,
      start: this.start,
      category: this.category,
      content_type: JSON.stringify(content_type),
      surgical_speciality: JSON.stringify(surgical_speciality),
      searchValue:searchValue
    };

    if (this.journalid) {
      this.filterDataApi(this.journalid)
    // this.subscriptions.push(articleSub);
    }
  }

  filterDataApi(journalid: string): void {

    const content_type = this.searchContextService.selectedContentType();
    const surgical_speciality = this.searchContextService.selectedSpecialities();
    const searchValue = this.searchContextService.searchContent()

    const params: any = {
      length: this.length,
      start: this.start,
      category: this.category,
      content_type: JSON.stringify(content_type),
      surgical_speciality: JSON.stringify(surgical_speciality),
      searchValue:searchValue
    };

    const articleSub = this.articleService.getArticleList(journalid, params).subscribe(result => {
      this.articles = result.data;
      this.total_count=result.total_count
      this.filteredArticles = [...this.articles];
      // this.totalPages = Math.ceil(this.filteredArticles.length / this.length);
      this.totalPages = Math.ceil(result.total_count / this.length);
      this.pageloading = false;
      
  })
  }
  getCategories(): void {
    this.articleService.getAllCategories().subscribe((response: any) => {
      // Assuming "academy" is the identifier for the main "Academy" menu
      const academyMenu = response.find((menu: any) => menu.identifier === 'bjs-academy');
  
      if (academyMenu) {
        // Filter out "Scientific Surgery" submenu
        const filteredSubMenus = academyMenu.subMenus.filter((subMenu: any) => subMenu.identifier !== 'scientific-surgery');
  
        // Include "Scientific Surgery" submenus
        const scientificSurgeryMenu = academyMenu.subMenus.find((subMenu: any) => subMenu.identifier === 'scientific-surgery');
        if (scientificSurgeryMenu) {
          filteredSubMenus.push(...scientificSurgeryMenu.subMenus);
        }
  
        // Update categories and categoryTitles
        this.categories = filteredSubMenus;
   
        this.categoryTitles = ['All', ...this.categories.map((subMenu: any) => subMenu.menu_name)];
     
       // this.setCategoryTitle();
      }
    });
  }
  handleCategory(event:any){
this.category=event;
// this.searchContextService.setSearchContent(this.searchValue)

// const content_type = this.searchContextService.selectedContentType();
// const surgical_speciality = this.searchContextService.selectedSpecialities();
const searchValue = this.searchContextService.searchContent()

// const params: any = {
//   length: this.length,
//   start: this.start,
//   category: this.category,
//   content_type: JSON.stringify(content_type),
//   surgical_speciality: JSON.stringify(surgical_speciality),
//   searchValue:searchValue
// };

if (this.journalid) {
  this.filterDataApi(this.journalid)
// this.subscriptions.push(articleSub);
}
this.filterDataApi(this.journalid)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      if (!x.closed) {
        x.unsubscribe();
      }
    });
  }
}



//Api for filtering



  // onFilterChange(filters: { surgical_speciality: string[], content_type: string[] }) {
  //   this.filteredArticles = this.articles.filter(article => {
  //     const articleSpecialities = article.surgical_speciality || [];
  //     const articleContentTypes = article.content_type || [];

  //     const matchesSurgicalSpeciality = filters.surgical_speciality.length === 0 || filters.surgical_speciality.some(speciality => {
  //       return articleSpecialities.includes(speciality);
  //     });
  //     const matchesContentType = filters.content_type.length === 0 || filters.content_type.some(type => articleContentTypes.includes(type));

  //     return matchesSurgicalSpeciality && matchesContentType;
  //   });
  //   this.totalPages = Math.ceil(this.filteredArticles.length / this.itemsPerPage);
  //   this.currentPage = 1;
  //   this.updateFilteredArticles();
  // }
  
  // onPageChange1(page: number) {
  //   this.route.params.subscribe(params => {
  //     this.category = params['category'];
  //     this.loadArticlesByCategory(this.category);
  //     this.load_filter = false;
  //   });
  //   this.currentPage = page;
  //   this.router.navigate([`/academy/${this.category}/page/${this.currentPage}`]);

  //   this.startArticle = this.length * (this.currentPage - 1);
  //   const params: any = {
  //       length: this.length ,
  //       start: this.startArticle,
  //       category:  this.category,
      
  //   };
  //   const articleSub = this.articleService.getArticleList(this.journalid, params).subscribe(result => {
  //     this.articles = result.data;
  //     this.filteredArticles = [...this.articles];
  //     this.totalPages = Math.ceil(this.filteredArticles.length / this.length);
  //     this.updateFilteredArticles();
  // }, error => {
   
  // });

  //   this.currentPage = page;
  //   this.updateFilteredArticles();
    
  // }



  //   handleSearch(searchValue: string) {
//     const params: any = {
//       length: this.length,
//       start: this.start,
//       category: this.category,
      

//     };
  
//     if(this.journalid){
//     if (searchValue.trim() === '') {
//       const articleSub = this.articleService.getArticleList(this.journalid, params).subscribe(result => {
//         this.articles = result.data; 
      
//         this.filteredArticles = [...this.articles];
//         this.totalPages = Math.ceil(this.filteredArticles.length / this.length);
//         this.updateFilteredArticles();
//       }, error => {
//       });
  
//       this.subscriptions.push(articleSub);
//     } else {
//       this.articleService.getArticles(searchValue, this.category).subscribe(
//         (data: any) => {
//           this.filteredArticles = data.data;
       
//         },
//         (error) => {
         
//         }
//       );
//     }
//   }
//   }