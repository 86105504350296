import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef, HostListener, inject, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ArticleService } from '../../services/article.service';
import { FilterServiceStateService } from '../../filter-service-state.service';
import { SearchContextService } from '../../services/search-context-service';

@Component({
    selector: 'app-article-filter',
    templateUrl: './article-filter.component.html',
    styleUrls: ['./article-filter.component.scss']
})
export class ArticleFilterComponent implements OnInit, OnDestroy {
    @Input() article: any[] = [];
    @Output() filterChange = new EventEmitter<{ surgical_speciality: string[], content_type: string[] }>();
    @ViewChild('filterCard') contentElements: ElementRef<HTMLDivElement> | undefined;

    surgicalView = true;
    contentTypeView = true;

    uniqueSurgicalSpecialities: string[] = [];
    uniqueContentTypes: string[] = [];
    subscription:Subscription[]=[];

    private readonly searchContextService = inject(SearchContextService);

    private routerSubscription: Subscription = new Subscription;
    pageLoading = true;
    loadingArray = new Array(5).fill(0);
    sub_category: any;
    constructor(private router: Router, private route: ActivatedRoute, private el: ElementRef, private articleService: ArticleService, private filterStateService: FilterServiceStateService) { }

    ngOnInit() {
        this.extractUniqueFilters();
        this.route.queryParams.subscribe((queryParams) => {
            if (queryParams['speciality']) {
                const filterValue = queryParams['speciality'].split(',').map((filter: string) => filter.trim());
                this.searchContextService.setSelectedSpecialities(filterValue);

            }

            if (queryParams['contentType']) {
                const contentType = queryParams['contentType'].split(',').map((filter: string) => filter.trim());
                this.searchContextService.setSelectedContentType(contentType);

            }



        });
        // this.loadSavedFilters()
        // this.resetSearchContext();

    }

    ngOnDestroy(): void {
        // Unsubscribe from router events to prevent memory leaks
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        if (this.el.nativeElement.contains(event.target)) {
            // Click inside filter box, do nothing
            return;
        }
        // Click outside filter box, close the filter
        this.closeFilter();
    }

    closeFilter() {
        this.surgicalView = false;
        this.contentTypeView = false;
    }
    apiData: any[] = [];


    extractUniqueFilters() {
        const routesub = this.route.params.subscribe(() => {
            this.sub_category = this.route.snapshot.params['subcategory'];
            // Assuming the method should be getFilterData
            this.articleService.getFilterData().subscribe(
                (result: any) => {
                    this.apiData = result;
                    this.uniqueSurgicalSpecialities = result['surgical_speciality']
                    this.uniqueContentTypes =this.sub_category ? [] : result['content_type']
                    this.pageLoading = false; 
                },
                error => {
                    //console.error('An error occurred:', error);
                }
            );
        });
        this.subscription.push(routesub);

    }

    loadSavedFilters() {
        this.filterStateService.getFilterState().subscribe(filters => {
            this.searchContextService.setSelectedSpecialities(filters.surgical_speciality);
            this.searchContextService.setSelectedContentType(filters.content_type);
            // this.selectedContentTypes = filters.content_type;
        });
    }
    onSpecialityChange(speciality: string) {
        let selectedSpecialities: string[] = this.searchContextService.selectedSpecialities();

        if (selectedSpecialities.includes(speciality)) {
            selectedSpecialities = selectedSpecialities.filter(s => s !== speciality);
        } else {
            selectedSpecialities.push(speciality);
        }

        this.searchContextService.setSelectedSpecialities(selectedSpecialities);

        // Update the query parameters
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                speciality: selectedSpecialities.join(','),
                contentType: this.searchContextService.selectedContentType().join(',')
            },
            queryParamsHandling: 'merge' // To preserve existing query params
        });

        this.emitFilterChange();
    }

    onContentTypeChange(type: string) {
        let selectedContentTypes = this.searchContextService.selectedContentType();

        if (selectedContentTypes.includes(type)) {
            selectedContentTypes = selectedContentTypes.filter(t => t !== type);
        } else {
            selectedContentTypes.push(type);
        }

        this.searchContextService.setSelectedContentType(selectedContentTypes);

        // Update the query parameters
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                speciality: this.searchContextService.selectedSpecialities().join(','),
                contentType: selectedContentTypes.join(',')
            },
            queryParamsHandling: 'merge' // To preserve existing query params
        });

        this.emitFilterChange();
    }
    private emitFilterChange() {
        this.filterChange.emit({
            surgical_speciality: this.searchContextService.selectedSpecialities(),
            content_type: this.searchContextService.selectedContentType()
        });

        this.filterStateService.setFilterState({
            surgical_speciality: this.searchContextService.selectedSpecialities(),
            content_type: this.searchContextService.selectedContentType()
        });
    }


    // onSpecialityChange(speciality: string) {
    //   let selectedSpecialities: string[] = this.searchContextService.selectedSpecialities()
    //   if (selectedSpecialities.includes(speciality)) {
    //     selectedSpecialities = selectedSpecialities.filter(s => s !== speciality);
    //   } else {
    //     selectedSpecialities.push(speciality);
    //   }

    //   this.searchContextService.setSelectedSpecialities(selectedSpecialities);




    //   this.filterChange.emit({
    //     surgical_speciality:  this.searchContextService.selectedSpecialities(),
    //     content_type: this.searchContextService.selectedContentType()
    //   })
    //   this.filterStateService.setFilterState({
    //     surgical_speciality: this.searchContextService.selectedSpecialities(),
    //     content_type: this.searchContextService.selectedContentType()
    //   });
    // }

    // onContentTypeChange(type: string) {
    //   let selectedContentTypes = this.searchContextService.selectedContentType();
    //   if (selectedContentTypes.includes(type)) {
    //     selectedContentTypes = selectedContentTypes.filter(t => t !== type);
    //   } else {
    //     selectedContentTypes.push(type);
    //   }
    //   this.searchContextService.setSelectedContentType(selectedContentTypes);


    //   this.filterChange.emit({
    //     surgical_speciality:  this.searchContextService.selectedSpecialities(),
    //     content_type: this.searchContextService.selectedContentType()
    //   })
    //   this.filterStateService.setFilterState({
    //     surgical_speciality: this.searchContextService.selectedSpecialities(),
    //     content_type: this.searchContextService.selectedContentType()
    //   });
    // }

    clearFilters() {
        this.searchContextService.setSelectedSpecialities([]);
        this.searchContextService.setSelectedContentType([]);
        this.searchContextService.clearFilters();
        this.filterChange.emit({ surgical_speciality: [], content_type: [] });
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            replaceUrl: true,
        });
    }

    isSpecialitySelected(speciality: string): boolean {
        return this.searchContextService.selectedSpecialities().includes(speciality);
    }

    isContentTypeSelected(type: string): boolean {
        return this.searchContextService.selectedContentType().includes(type);
    }

    resetSelectedFilters() {
        this.searchContextService.setSelectedSpecialities([]);
        this.searchContextService.setSelectedContentType([]);
        //this.emitFilterChange();
    }
    private resetSearchContext(): void {
        this.searchContextService.setSelectedSpecialities([]);
        this.searchContextService.setSelectedContentType([]);
        this.searchContextService.setSearchContent(undefined);
    }
    ngAfterViewInit() {
        let headings = this.contentElements?.nativeElement.querySelectorAll("[class='section-heading']");
        headings?.forEach((eachHead, index: number) => {
            let textWidth = this.getTextWidth(eachHead.innerHTML, getComputedStyle(eachHead));
            let uniqueClass = `section-heading-${index}`;
            eachHead.classList.add(uniqueClass);

            // Create a style element to apply to the heading's ::after pseudo-element
            let styleElement = document.createElement('style');
            styleElement.innerHTML = `
          .${uniqueClass}::after {
            content: "";
            position: absolute;
            top: 50%;
            height: 2px;
            left: 95%;
            width: 100px;
            background-color: black;
            font-size: 1.25rem;
            width: ${textWidth}px;
          }
        `;

            // Append the style element to the document head
            document.head.appendChild(styleElement);
        })
    }

    getTextWidth(text: string, style: CSSStyleDeclaration): number {
        // Create a temporary element to measure text width
        let tempElement = document.createElement('span');
        tempElement.style.font = style.font;
        tempElement.style.visibility = 'hidden';
        tempElement.style.whiteSpace = 'nowrap';
        tempElement.innerText = text;

        document.body.appendChild(tempElement);
        let width = tempElement.getBoundingClientRect().width;
        document.body.removeChild(tempElement);

        return width;
    }
}




